
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Columns extends Vue {
  @Prop({ required: false })
  content?: any;
  @Prop({ required: false })
  element?: any;
}
